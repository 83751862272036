import request from '@/utils/request'

// 城市合伙人详细信息
export function listById(query) {
  return request({
    url: '/partnerGinseng/listById',
    method: 'get',
    params: query
  })
}
// 城市合伙人分页查询
export function partnerGinsengListPage(query) {
  return request({
    url: '/partnerGinseng/listPage',
    method: 'get',
    params: query
  })
}
